import React from "react";

function Type() {
  return (
    <div style={{ fontSize: 30 }}>
      Fresh business school graduate in Finance and accounting management
    </div>
  );
}

export default Type;
